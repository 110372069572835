<template>
  <div>
    <SubNav title="Data records" />
    <div class="container-fluid pb-1">
      <div class="mb-sm-4 mx-3 my-4 row" style="top: 75px">
        <div class="col-lg-12">
          <div class="border">
            <!--  -->
            <div class="row p-4">
              <div class="col-lg-4 col-md-6 mb-1">
                <span>{{ tableDescription }}</span>
              </div>
              <div class="col-lg-4 col-md-6">
                <b-form @submit.prevent="performSearch">
                  <b-form-input
                    v-model="search"
                    title="Press Enter to search ..."
                    :placeholder="ps"
                    type="search"
                    @keyup.enter="performSearch"
                  ></b-form-input>
                </b-form>
              </div>
              <div class="col-lg-4 col-md-6 float-right">
                <button class="btn mr-2 float-right btn-success option-btn btn-block">
                  Update Records
                </button>
                <b-dropdown
                  id="dropdown-1"
                  text="Share this Page"
                  class="float-right mr-2"
                  variant="primary"
                  block
                >
                  <b-dropdown-item>Share via Link</b-dropdown-item>
                  <b-dropdown-item>Share to user </b-dropdown-item>
                </b-dropdown>
                <button class="btn mr-2 float-right btn-primary option-btn btn-block">
                  Download
                </button>
              </div>
            </div>

            <!--  -->
            <div class="row pt-3 px-3 py-3">
              <div class="col-lg-2 mb-1"></div>

              <div v-for="entry in filters" :key="entry.name" class="float-right col">
                <el-select
                  v-model="tableFilters[entry.name]"
                  clearable
                  filterable
                  :placeholder="entry.label"
                  class="mr-2 row"
                >
                  <el-option
                    v-for="option in entry.options"
                    :key="option"
                    :value="option"
                    :label="option"
                  >
                  </el-option>
                </el-select>
              </div>

              <div class="col-lg-2 mb-1">
                <button
                  class="btn option-btn btn-block btn-warning"
                  @click.prevent="handleDeleteSelectedCells"
                >
                  Delete <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                  <small v-if="selectedRows.length > 0" class="tab__badge text-white">{{
                    selectedRows.length
                  }}</small>
                </button>
              </div>
            </div>

            <!-- Default Props are defined in ./Mixin -->
            <TableComponent
              :fields="fields"
              bordered
              :data="preview"
              :loading="tableLoading"
              :empty-text="emptyText"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              @sort:by="(by) => (sortBy = by)"
              @sort:direction="(isDescending) => (sortDesc = isDescending)"
              @sizeChange="handlePageSizeChange"
              @selected="handleSelection"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubNav from "../../components/NavigationSubNav.vue";
import { mapGetters } from "vuex";
import TableComponent from "../../components/Table.vue";
import { random } from "../../utils";
import DataMixin from "./mixin";
import { DateTime } from "luxon";
import lunar from "lunr";

const formatter = (value) => {
  return new DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
};

const searchPlaceHolders = [
  "level:National",
  "level:Sub-national",
  "location:Imo",
  "target:8*",
  "target:80",
  "value:89",
  "target:7*",
  "level:National location:Imo target:8*",
  "level:National target:8*",
];

const searchFields = ["indicator", "target", "value", "location", "level"];

const normalizeDate = function (builder) {
  var pipelineFunction = function (token) {
    return token;
  };
  // Register the pipeline function so the index can be serialized
  lunar.Pipeline.registerFunction(pipelineFunction, "normalizeDate");
  // Add the pipeline function to both the indexing pipeline and the
  // searching pipeline
  builder.pipeline.before(lunar.stemmer, pipelineFunction);
  builder.searchPipeline.before(lunar.stemmer, pipelineFunction);
};

const percentageFormatter = (value) => `${value}%`;

export default {
  name: "DataRecords",
  components: {
    TableComponent,
    SubNav,
  },
  mixins: [DataMixin],

  data() {
    return {
      filtersFrom: "preview",
      sortableFields: [
        { name: "indicator", label: "Select Indicator" },
        { name: "target", label: "Select Target" },
        { name: "location", label: "Select Location" },
        { name: "level", label: "Select Level" },
      ],
      sortBy: "created_at",
      sortDesc: true,
      fetchInterval: undefined,
      search: undefined,
      ps: searchPlaceHolders[random(0, searchPlaceHolders.length - 1)],
    };
  },
  computed: {
    ...mapGetters({ records: "data/records" }),
    fields() {
      return [
        { key: "indicator", sortable: true },
        { key: "level", sortable: true },
        { key: "location", sortable: true, label: "Country/State" },
        {
          key: "target",
          label: "National Target",
          sortable: true,
          formatter: percentageFormatter,
        },
        { key: "value", sortable: true, formatter: percentageFormatter },
      ];
    },
    preview() {
      if (this.search && window.recordsSearchIndex) {
        try {
          const results = window.recordsSearchIndex.search(this.search);
          const records = results.map((x) => {
            return this.records.find((y) => y.id == parseInt(x.ref, 10));
          });
          return records;
        } catch (error) {
          console.log(error);
        }
      }
      return this.records;
    },
    tableDescription() {
      const amount =
        this.perPageSize > this.preview.length ? this.preview.length : this.perPageSize;
      return `Showing ${amount} of ${this.preview.length} records`;
    },
  },
  created() {
    if (!this.records.length) {
      return this.loadRecords();
    }
    this.buildSearchIndex(JSON.parse(JSON.stringify(this.records)));
  },
  beforeDestroy() {
    if (this.fetchInterval) clearInterval(this.fetchInterval);
  },
  mounted() {
    this.fetchInterval = setInterval(() => {
      this.loadRecords();
    }, 600000);
  },
  methods: {
    /**
     *
     */
    performSearch() {},
    /**
     *
     */
    loadRecords() {
      if (this.tableLoading) return;
      this.tableLoading = true;
      this.$store
        .dispatch("data/fetchRecords")
        .catch((error) => {
          const statusCode = error?.response?.status || 500;
          if (403 == statusCode) {
            // @dev render test data
            this.$store.commit("data/SET_RECORDS", this.generateTestData());
            this.$notify({
              position: "top-right",
              message: "Error fetching records: StatusCode::403. Using test data",
              duration: 0,
              type: "warning",
            });
          }
        })
        .finally(() => {
          this.tableLoading = false;
          this.buildSearchIndex(JSON.parse(JSON.stringify(this.records)));
        });
    },
    /**
     * High performant search algorithm, performs a local search on records
     * present in the store
     *
     * @see https://lunrjs.com/
     */
    async buildSearchIndex(records) {
      records = records.map((x) => {
        x.created_at = formatter(x.created_at);
        return x;
      });

      const index = lunar(function () {
        this.use(normalizeDate);
        searchFields.forEach(function (field) {
          this.field(field);
        }, this);

        this.ref("id");

        records.forEach(function (doc) {
          this.add(doc);
        }, this);
      });

      // Store on window to avoid memory leak on large datasets
      window.recordsSearchIndex = index;
    },

    // TODO fix error while loading lists
    generateTestData() {
      return new Array(50).fill(0).map((_, index) => {
        let x = {};
        x.id = index;

        x.indicator = "Skilled Birth Attendance";
        x.level = ["Sub-national", "National"][random(0, 2)];
        x.location = ["Nigeria", "Rivers", "Yobe", "Taraba"][random(0, 3)];
        x.target = random(0, 100);
        x.value = random(0, 80);
        return x;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
input::placeholder {
  color: #dae2e9;
  opacity: 1;
}
input:-ms-input-placeholder {
  color: #dae2e9;
  opacity: 1;
}

input::-ms-input-placeholder {
  color: #dae2e9;
  opacity: 1;
}
</style>
